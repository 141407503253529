<template>
  <v-dialog
    :value="value"
    width="100%"
    max-width="800"
    persistent
    :retain-focus="false"
  >
    <v-card class="pa-5" min-height="450">
      <v-card-title class="pt-0 px-0">
        <template v-if="$props.event"> Termin bearbeiten </template>
        <template v-else> Neuer Termin </template>
        <v-spacer />
        <v-btn icon @click="resetEventData">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-text-field
        v-model="singleEvent.name"
        placeholder="Titel und Uhrzeit einfügen"
        outlined
        autofocus
        clearable
        class="text-h5"
      ></v-text-field>
      <template
        v-if="singleEvent.startDate < singleEvent.endDate && singleEvent.timed"
      >
        <v-row dense align="center">
          <!-- Datepicker startdate -->
          <v-col cols="12" md="4">
            <v-menu ref="startDateMenu" max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedStartDate"
                  dense
                  outlined
                  readonly
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singleEvent.startDate"
                locale="de"
                first-day-of-week="1"
                no-title
                scrollable
                @input="onUpdateStartDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-------------------------->
          <v-col cols="12" md="4">
            <v-row no-gutters justify="center" align="center">
              <v-col cols="5">
                <!-- Datepicker starttime -->
                <v-autocomplete
                  v-model="singleEvent.startTime"
                  :items="timeSlots"
                  append-icon=""
                  dense
                  outlined
                  hide-details
                  :menu-props="{ auto: true, minWidth: '150px' }"
                  @input="onUpdateStartTime"
                  @keydown.enter="onUpdateStartTime"
                ></v-autocomplete>
                <!-------------------------->
              </v-col>
              <v-col cols="2" class="text-center">
                <span aria-label=" bis ">–</span>
              </v-col>
              <v-col cols="5">
                <!-- Autocomplete endtime -->
                <v-autocomplete
                  v-model="singleEvent.endTime"
                  :items="timeSlots"
                  append-icon=""
                  dense
                  outlined
                  hide-details
                  :menu-props="{ auto: true, minWidth: '150px' }"
                  @input="onUpdateEndTime"
                  @keydown.enter="onUpdateEndTime"
                ></v-autocomplete>
                <!------------------------>
              </v-col>
            </v-row>
          </v-col>
          <!-- Datepicker enddate -->
          <v-col cols="12" md="4">
            <v-menu max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedEndDate"
                  dense
                  outlined
                  hide-details
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singleEvent.endDate"
                locale="de"
                first-day-of-week="1"
                :min="minDate"
                no-title
                scrollable
                @input="onUpdateEndDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-------------------------->
        </v-row>
      </template>
      <!-- TIMED -->
      <template v-else-if="singleEvent.timed">
        <v-row align="center">
          <!-- Datepicker startdate -->
          <v-col cols="12" md="6">
            <v-menu ref="startDateMenu" max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedStartDate"
                  dense
                  outlined
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singleEvent.startDate"
                locale="de"
                first-day-of-week="1"
                no-title
                scrollable
                @input="onUpdateStartDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-------------------------->
          <v-col cols="12" md="6">
            <v-row no-gutters justify="center" align="center">
              <v-col cols="5">
                <!-- Datepicker starttime -->
                <v-autocomplete
                  v-model="singleEvent.startTime"
                  :items="timeSlots"
                  append-icon=""
                  dense
                  outlined
                  hide-details
                  :prepend-icon="icons.mdiCalendarClock"
                  :menu-props="{ auto: true, minWidth: '150px' }"
                  @input="onUpdateStartTime"
                ></v-autocomplete>
                <!-------------------------->
              </v-col>
              <v-col cols="2" class="text-center">
                <span aria-label=" bis ">–</span>
              </v-col>
              <v-col cols="5">
                <!-- Autocomplete endtime -->
                <v-autocomplete
                  v-model="singleEvent.endTime"
                  :items="timeSlots"
                  append-icon=""
                  dense
                  outlined
                  hide-details
                  :prepend-icon="icons.mdiCalendarClock"
                  :menu-props="{ auto: true, minWidth: '150px' }"
                  @input="onUpdateEndTime"
                  @keydown.enter="onUpdateEndTime"
                ></v-autocomplete>
                <!------------------------>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row align="center">
          <!-- Datepicker startdate -->
          <v-col cols="12" md="6">
            <v-menu ref="startDateMenu" max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedStartDate"
                  dense
                  outlined
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singleEvent.startDate"
                locale="de"
                first-day-of-week="1"
                no-title
                scrollable
                @input="onUpdateStartDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-------------------------->
          <!-- Datepicker enddate -->
          <v-col cols="12" md="6">
            <v-menu max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedEndDate"
                  dense
                  outlined
                  hide-details
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singleEvent.endDate"
                locale="de"
                first-day-of-week="1"
                :min="minDate"
                no-title
                scrollable
                @input="onUpdateEndDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-------------------------->
        </v-row>
      </template>
      <v-row no-gutters justify-md="space-between" align-md="center">
        <v-checkbox
          v-model="singleEvent.timed"
          label="Ganztägig?"
          class="d-inline-block"
          :true-value="false"
          :false-value="true"
          @change="onUpdateAllDay"
        ></v-checkbox>
        <div class="colors">
          <v-btn
            v-for="color in colors"
            :key="color"
            icon
            small
            @click="onUpdateColor(color)"
          >
            <v-icon :color="color">{{ icons.mdiCircle }}</v-icon>
          </v-btn>
        </div>
      </v-row>
      <!-- Benachrichtigung -->
      <v-row v-if="singleEvent.timed" align="center" dense class="mb-4">
        <v-col cols="12" md="4">
          <v-select
            v-model="singleEvent.notificationType"
            dense
            hide-details
            outlined
            :prepend-icon="icons.mdiBell"
            :items="['E-Mail', 'Benachrichtigung']"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model.number="singleEvent.notificationValue"
            dense
            hide-details
            outlined
            type="number"
            @input="onUpdateNotificationData"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="singleEvent.notificationUnit"
            dense
            hide-details
            outlined
            :items="units"
            item-text="name"
            item-value="value"
            @change="onUpdateNotificationData"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" class="text-right"
          >vorher: {{ formatDateTime(singleEvent.notificationDateTime) }}</v-col
        >
      </v-row>
      <v-row v-else dense align="center" class="mb-4">
        <v-col cols="12" md="4">
          <v-select
            v-model="singleEvent.notificationType"
            dense
            hide-details
            outlined
            :prepend-icon="icons.mdiBell"
            :items="['E-Mail', 'Benachrichtigung']"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model.number="singleEvent.notificationValue"
            dense
            hide-details
            outlined
            type="number"
            @input="onUpdateNotificationData"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="singleEvent.notificationUnit"
            dense
            hide-details
            outlined
            :items="units"
            item-text="name"
            item-value="value"
            @change="onUpdateNotificationData"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" class="text-right"
          >vorher: {{ formatDateTime(singleEvent.notificationDateTime) }}</v-col
        >
      </v-row>
      <!-- Gast -->
      <template v-if="!user">
        <v-btn
          v-if="!showGuestTextField"
          block
          depressed
          class="mb-4"
          @click="displayGuestTextField"
        >
          <v-icon>{{ icons.mdiAccountMultiple }}</v-icon> Person hinzufügen
        </v-btn>
        <v-row v-else align="center" class="mb-1">
          <v-col cols="12" md="3">
            <v-select
              v-model="guestModel"
              :items="guestModels"
              label="Interessent / Kunde"
              dense
              outlined
              hide-details
              @change="onChangeGuestModel"
            ></v-select>
          </v-col>
          <v-col cols="12" md="9">
            <v-autocomplete
              v-model="singleEvent.guest"
              :items="guestItems"
              label="Teilnehmer"
              item-text="name"
              item-value="id"
              :loading="fetchingSearchResults"
              :disabled="fetchingSearchResults"
              :search-input.sync="searchQuery"
              clearable
              hide-details
              outlined
              dense
              return-object
              class="align-center"
              @click:clear="onResetSearch"
            >
              <template v-slot:append-outer>
                <v-btn icon @click="hideGuestTextField">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <v-text-field
        v-if="user"
        outlined
        disabled
        readonly
        color="primary"
        class="mb-4"
        hide-details
        :value="guestName"
      >
      </v-text-field>
      <!-- Ort -->
      <v-btn
        v-if="!showPlaceField"
        block
        depressed
        class="mb-4"
        @click="showPlaceField = true"
      >
        <v-icon>{{ icons.mdiMapMarker }}</v-icon> Ort hinzufügen
      </v-btn>
      <v-select
        v-else
        v-model="singleEvent.place"
        label="Ort"
        hide-details
        outlined
        dense
        :items="places"
        class="mb-4 align-center"
      >
        <template v-slot:append-outer>
          <v-btn icon @click="hidePlaceField">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </template>
      </v-select>

      <!-- Description -->
      <v-btn
        v-if="!showDescriptionField"
        block
        depressed
        class="mb-4"
        @click="showDescriptionField = true"
      >
        <v-icon>{{ icons.mdiText }}</v-icon> Beschreibung hinzufügen
      </v-btn>
      <v-textarea
        v-else
        ref="descriptionField"
        v-model="singleEvent.description"
        outlined
      >
        <template v-slot:append-outer>
          <v-btn icon @click="showDescriptionField = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </template>
      </v-textarea>
      <v-card-actions class="px-0">
        <v-spacer />
        <v-btn large depressed class="mr-md-4" @click="resetEventData"
          >Abbrechen</v-btn
        >
        <v-btn
          :disabled="!singleEvent.name || saving"
          large
          depressed
          :loading="saving"
          class="white--text"
          color="cta"
          @click="saveEvent"
          >Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiCalendar,
  mdiCalendarClock,
  mdiCircle,
  mdiBell,
  mdiMapMarker,
  mdiText,
  mdiAccountMultiple,
  mdiMagnify,
} from "@mdi/js";
import { DateTime } from "luxon";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import debounce from "lodash-es/debounce";

export default {
  name: "EventDialog",
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    user: {
      type: Object,
      default: () => null,
      required: false,
    },
    userModel: {
      type: String,
      default: "Interessent",
      required: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    fetchUser: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
  },
  data: () => ({
    icons: {
      mdiClose,
      mdiCalendar,
      mdiCalendarClock,
      mdiCircle,
      mdiBell,
      mdiMapMarker,
      mdiText,
      mdiAccountMultiple,
      mdiMagnify,
    },
    guestModels: [
      { value: "User", text: "Interessent" },
      { value: "Customer", text: "Kunde" },
    ],
    guestModel: "User",
    singleEvent: {
      color: "primary",
      description: null,
      end: null,
      endDate: null,
      endTime: null,
      guest: null,
      name: null,
      notificationDateTime: DateTime.now().toFormat("yyyy-LL-dd 09:00"),
      notificationType: "Benachrichtigung",
      notificationUnit: "day",
      notificationValue: 1,
      place: null,
      searchQuery: null,
      start: null,
      startDate: null,
      startTime: null,
      timed: false,
      user: null,
    },
    saving: false,
    timeSlots: [],
    colors: [
      "primary",
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    searchResults: [],
    places: [
      "Firma",
      "Beim Kunden",
      "Außerhalb",
      "Auf der Baustelle",
      "Home Office",
      "Online (Videocall)",
    ],
    notificationUnits: [
      { name: "Minuten", value: "minute" },
      { name: "Stunden", value: "hour" },
      { name: "Tage", value: "day" },
      { name: "Wochen", value: "week" },
    ],
    showGuestTextField: false,
    searchQuery: null,
    showPlaceField: false,
    showDescriptionField: false,
    fetchingSearchResults: false,
  }),
  computed: {
    units() {
      if (!this.singleEvent.timed) {
        return this.notificationUnits.map((unitItem) => {
          return unitItem.name === "Tage" || unitItem.name === "Wochen"
            ? unitItem
            : null;
        });
      } else {
        return this.notificationUnits;
      }
    },
    guestName() {
      return this.user ? `${this.user.vorname} ${this.user.nachname}` : "";
    },
    guestItems() {
      return this.searchResults.map((user) => {
        let name = "";

        if (user.bauvorhaben?.length > 1) {
          name = `${user.vorname} ${user.nachname} (${user.bauvorhaben
            .map((bv) => bv.bv_nr)
            .join(", ")})`;
        } else if (user.bauvorhaben?.length === 1) {
          name = `${user.vorname} ${user.nachname} (${user.bauvorhaben[0].bv_nr})`;
        } else {
          name = `${user.vorname} ${user.nachname}`;
        }

        return {
          id: user.id,
          uuid: user.uuid,
          name: name,
        };
      });
    },
    minDate() {
      return this.singleEvent.startDate
        ? this.singleEvent.startDate
        : DateTime.now().toISODate();
    },
    formattedStartDate() {
      return this.singleEvent.startDate
        ? DateTime.fromISO(this.singleEvent.startDate)
            .setLocale("de-DE")
            .toFormat("cccc, d. LLLL")
        : "";
    },
    formattedEndDate() {
      return this.singleEvent.endDate
        ? DateTime.fromISO(this.singleEvent.endDate)
            .setLocale("de-DE")
            .toFormat("cccc, d. LLLL")
        : "";
    },
    isCalendarView() {
      return this.$route.name === "calendar";
    },
  },
  mounted() {
    if (this.timeSlots.length === 0) {
      this.generateTimeSlots();
    }
  },
  watch: {
    value(val) {
      if (val === true) {
        if (this.event) {
          this.initializeEventFields();
        } else {
          this.createBlankEvent();
        }
      }
    },
    searchQuery(val) {
      if (this.searchResults.length > 0) {
        return;
      }

      if (this.fetchingSearchResults) {
        return;
      }

      this.debouncedSearch(val);
    },
  },
  methods: {
    initializeEventFields() {
      this.singleEvent = { ...this.event };
      this.singleEvent.startDate = DateTime.fromSQL(
        this.event.start
      ).toISODate();
      this.singleEvent.endDate = DateTime.fromSQL(this.event.end).toISODate();
      this.singleEvent.startTime = DateTime.fromSQL(this.event.start).toFormat(
        "HH:mm"
      );
      this.singleEvent.endTime = DateTime.fromSQL(this.event.end).toFormat(
        "HH:mm"
      );

      this.singleEvent.guestModel = this.userModel;
    },
    createBlankEvent() {
      const startDate = DateTime.now().toISODate();
      const endDate = startDate;

      this.setStart(startDate);
      this.setEnd(endDate);

      if (this.user) {
        this.singleEvent.guest = {
          id: this.user.id,
          uuid: this.user.uuid,
          name: `${this.user.vorname} ${this.user.nachname}`,
        };

        this.singleEvent.guestModel = this.userModel;

        this.showGuestTextField = true;
      }

      this.onUpdateNotificationData();
    },
    onChangeGuestModel() {
      this.searchQuery = null;
      this.searchResults = [];
      this.singleEvent.guest = null;
    },
    displayGuestTextField() {
      this.showGuestTextField = true;
    },
    generateTimeSlots() {
      const startTime = DateTime.local().startOf("day");
      const endTime = DateTime.local().endOf("day");

      let currentTime = startTime;

      while (currentTime <= endTime) {
        this.timeSlots.push(
          currentTime.toLocaleString(DateTime.TIME_24_SIMPLE)
        );
        currentTime = currentTime.plus({ minutes: 15 });
      }
    },
    getTimeSlotNearCurrentTime() {
      const currentDateTime = DateTime.now();
      let nearestTime = this.timeSlots[0];
      let timeDiff = Math.abs(
        currentDateTime.diff(DateTime.fromISO(nearestTime)).as("minutes")
      );

      for (const time of this.timeSlots) {
        const optionDateTime = DateTime.fromISO(time);
        const diff = Math.abs(
          currentDateTime.diff(optionDateTime).as("minutes")
        );

        if (diff < timeDiff) {
          nearestTime = time;
          timeDiff = diff;
        }
      }

      return nearestTime;
    },
    hideGuestTextField() {
      this.searchQuery = null;
      this.searchResults = [];
      this.showGuestTextField = false;
      this.singleEvent.guest = null;
    },
    hidePlaceField() {
      this.showPlaceField = false;
      this.singleEvent.place = null;
    },
    onResetSearch() {
      this.searchQuery = null;
      this.searchResults = [];
    },
    onUpdateAllDay(timed) {
      if (timed === true) {
        this.setStart(
          this.singleEvent.startDate,
          this.getTimeSlotNearCurrentTime()
        );

        this.setEnd(
          this.singleEvent.startDate,
          DateTime.fromISO(this.singleEvent.startTime)
            .plus({ hours: 1 })
            .toLocaleString(DateTime.TIME_24_SIMPLE)
        );
      } else {
        this.setStart(this.singleEvent.startDate);
        this.setEnd(this.singleEvent.endDate);
      }

      this.setNotificationData();
    },
    onUpdateColor(color) {
      this.singleEvent.color = color;
    },
    onUpdateNotificationData() {
      const calculateNotificationDateTime = (
        startISO,
        unit,
        value,
        timeFormat = "HH:mm"
      ) => {
        return DateTime.fromISO(startISO)
          .minus({ [unit]: value })
          .toFormat(`yyyy-LL-dd ${timeFormat}`);
      };

      const startISO =
        this.singleEvent.notificationUnit === "day" ||
        this.singleEvent.notificationUnit === "week"
          ? DateTime.fromSQL(this.singleEvent.start).toISO({
              includeOffset: false,
            })
          : `${this.singleEvent.startDate}T${this.singleEvent.startTime}:00`;

      const unitsMap = {
        minute: "minutes",
        hour: "hours",
        day: "days",
        week: "weeks",
      };

      const unit = unitsMap[this.singleEvent.notificationUnit] || "weeks";

      this.singleEvent.notificationDateTime = calculateNotificationDateTime(
        startISO,
        unit,
        this.singleEvent.notificationValue
      );
    },
    setNotificationData() {
      if (this.singleEvent.timed) {
        this.singleEvent.notificationValue = 10;
        this.singleEvent.notificationUnit = "minute";
      } else {
        this.singleEvent.notificationValue = 1;
        this.singleEvent.notificationUnit = "day";
      }

      this.onUpdateNotificationData();
    },
    onUpdateStartDate() {
      let startDate = DateTime.fromISO(this.singleEvent.startDate);
      let endDate = DateTime.fromISO(this.singleEvent.endDate);

      if (startDate.ts > endDate.ts) {
        endDate = startDate;
      }

      if (this.singleEvent.startTime) {
        this.setStart(startDate.toISODate(), this.singleEvent.startTime);
      } else {
        this.setStart(startDate.toISODate());
      }

      if (this.singleEvent.endTime) {
        this.setEnd(endDate.toISODate(), this.singleEvent.endTime);
      } else {
        this.setEnd(endDate.toISODate());
      }

      this.onUpdateNotificationData();
    },
    onUpdateStartTime() {
      const startTime = DateTime.fromISO(this.singleEvent.startTime);
      const endTime = DateTime.fromISO(this.singleEvent.startTime).plus({
        hours: 1,
      });

      this.setStart(this.singleEvent.startDate, startTime);
      this.setEnd(this.singleEvent.endDate, endTime);

      this.onUpdateNotificationData();
    },
    onUpdateEndDate() {
      const endDate = DateTime.fromISO(this.singleEvent.endDate);

      if (this.singleEvent.endTime) {
        this.setEnd(endDate.toISODate(), this.singleEvent.endTime);
      } else {
        this.setEnd(endDate.toISODate());
      }
    },
    onUpdateEndTime() {
      const endTime = DateTime.fromISO(this.singleEvent.endTime);

      this.setEnd(this.singleEvent.endDate, endTime);

      this.onUpdateNotificationData();
    },
    resetEventData() {
      this.guestModel = "User";
      this.searchQuery = null;
      this.searchResults = [];
      this.showGuestTextField = false;
      this.showPlaceField = false;
      this.showDescriptionField = false;

      this.singleEvent = {
        color: "primary",
        description: null,
        end: null,
        endDate: null,
        endTime: null,
        guest: null,
        name: null,
        notificationDateTime: DateTime.now().toFormat("yyyy-LL-dd 09:00"),
        notificationType: "Benachrichtigung",
        notificationUnit: "day",
        notificationValue: 1,
        place: null,
        searchQuery: null,
        start: null,
        startDate: null,
        startTime: null,
        timed: false,
        user: null,
      };

      this.$emit("cancel");
    },
    async saveEvent() {
      this.saving = true;

      if (this.event && this.event.id) {
        await this.updateEvent();
      } else {
        await this.storeEvent();
      }

      this.saving = false;
    },
    async storeEvent() {
      await ApiService.storeEvent(this.singleEvent)
        .then((res) => {
          this.$emit("submit", res.data.data);

          const notification = {
            type: "success",
            title: "Erledigt",
            message: "Termin erfolgreich gespeichert.",
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.resetEventData();
        });
    },
    async updateEvent() {
      await ApiService.updateEvent(this.singleEvent)
        .then((res) => {
          this.$emit("submit", res.data.data);

          const notification = {
            type: "success",
            title: "Erledigt",
            message: "Termin erfolgreich aktualisiert.",
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.resetEventData();
        });
    },
    debouncedSearch: debounce(async function (query) {
      const data = {
        query: query,
        model: this.guestModel,
        associatedOnly: true,
      };

      if (query && query.length > 1) {
        this.fetchingSearchResults = true;
        await ApiService.search(data)
          .then((res) => {
            this.searchResults = res.data.collection;
          })
          .catch((err) => {
            const notification = {
              type: "error",
              title: "Fehler",
              error: getError(err),
            };

            this.$store.commit("notifications/ADD", notification);
          })
          .finally(() => {
            this.fetchingSearchResults = false;
          });
      }
    }, 500),
    setEndDate(date) {
      this.singleEvent.endDate = date;
    },
    setEndTime(time) {
      this.singleEvent.endTime = time;
    },
    setStart(date, time = null) {
      const startDate = DateTime.fromISO(date).toISODate();
      const startTime = time
        ? DateTime.fromISO(time).toLocaleString(DateTime.TIME_24_SIMPLE)
        : null;

      this.setStartDate(startDate);
      this.setStartTime(startTime);

      this.singleEvent.start = startTime
        ? `${startDate} ${startTime}`
        : startDate;
    },
    setEnd(date, time = null) {
      const endDate = DateTime.fromISO(date).toISODate();
      const endTime = time
        ? DateTime.fromISO(time).toLocaleString(DateTime.TIME_24_SIMPLE)
        : null;

      this.setEndDate(endDate);
      this.setEndTime(endTime);

      this.singleEvent.end = endTime ? `${endDate} ${endTime}` : endDate;
    },
    setStartDate(date) {
      this.singleEvent.startDate = date;
    },
    setStartTime(time) {
      this.singleEvent.startTime = time;
    },
    formatDateTime(date) {
      let format = DateTime.DATE_SHORT;

      if (this.singleEvent.timed) {
        format = DateTime.DATETIME_SHORT;
      }
      return date ? DateTime.fromSQL(date).toLocaleString(format) : "";
    },
  },
};
</script>

<style scoped></style>
