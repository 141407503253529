<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2">
        <v-sheet outlined rounded>
          <v-list rounded nav>
            <v-list-item
              v-for="(navItem, index) in navItems"
              :key="`nav-item-${index}`"
            >
              <v-list-item-icon>
                <v-icon color="primary">{{ navItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link
                    :to="navItem.path"
                    class="text-decoration-none"
                    active-class="font-weight-bold"
                    exact
                  >
                    {{ navItem.title }}
                  </router-link>
                </v-list-item-title>
                <template v-for="(child, index) in navItem.children">
                  <v-list-item-subtitle
                    v-if="showNavChildItem(child)"
                    :key="`child-${index}`"
                    class="mb-2"
                  >
                    <router-link
                      :to="child.path"
                      class="text-decoration-none"
                      active-class="font-weight-bold"
                    >
                      {{ child.title }}
                      <span v-if="child.title === 'Katalogbestellungen'"
                        >({{ catalogueRequestsCount }})</span
                      ><span v-else-if="child.title === 'Bauberatungen'"
                        >({{ bauberatungenCount }})</span
                      >
                    </router-link></v-list-item-subtitle
                  >
                </template>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="10">
        <v-card class="pa-5">
          <v-card-title class="pt-0 px-0">
            <v-btn icon class="mr-2" @click="$router.go(-1)">
              <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
            <span class="text-h5 mr-3"
              >{{ user.anrede }} {{ user.vorname }} {{ user.nachname }}</span
            >
            <v-rating
              v-model="user.rating"
              clearable
              :empty-icon="icons.mdiStarOutline"
              :full-icon="icons.mdiStar"
              :half-icon="icons.mdiStarHalfFull"
              length="5"
              hover
              background-color="primary"
              color="primary"
              @input="ratingEdited = true"
            >
            </v-rating>
            <template v-if="ratingEdited">
              <v-btn
                icon
                :loading="savingRating"
                :disabled="savingRating"
                @click="submitRating"
              >
                <v-icon>{{ icons.mdiContentSave }}</v-icon>
              </v-btn>
              <v-btn
                icon
                :loading="savingRating"
                :disabled="savingRating"
                @click="cancelRatingInput"
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </template>
            <v-spacer />
            <v-menu v-if="isAuthUserAssociated" left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>
              <v-list dense nav>
                <v-list-item
                  v-if="
                    $can('interessenten_edit') ||
                    authUser.id === user.verkaeufer.id
                  "
                  link
                  :to="{ name: 'edit-interessent', params: { id: $props.id } }"
                >
                  <v-list-item-title> Bearbeiten </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="user.verkaeufer && $can('customers_create')"
                  link
                  @click="openCustomerCreationDialog"
                >
                  <v-list-item-title> Zum Kunden umwandeln </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="$can('interessent_mark_as_spam')"
                  link
                  @click="confirmMarkAsSpamDialog = true"
                >
                  <v-list-item-title class="warning--text">
                    Als Spam markieren
                  </v-list-item-title>
                </v-list-item>
                <template v-if="$can('interessenten_activate_deactivate')">
                  <v-list-item v-if="user.aktiv" link @click="deactivateUser">
                    <v-list-item-title class="warning--text">
                      Deaktivieren
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-else link @click="activateUser">
                    <v-list-item-title class="success--text">
                      Aktivieren
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item
                  v-if="$can('interessenten_delete')"
                  link
                  @click="deleteDialog = true"
                >
                  <v-list-item-title class="error--text">
                    Löschen
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-alert v-if="!user.aktiv" dense text type="warning"
            >{{ user.vorname }} {{ user.nachname }} ist inaktiv!</v-alert
          >
          <v-alert
            v-if="
              user.benutzertyp === 'Interessent' &&
              !$hasPermission(authUser, 'Verkauf')
            "
            v-show="!user.verkaeufer && !user.verkaeufer_id"
            color="warning"
            type="warning"
            class="mt-4"
            prominent
          >
            <div class="d-flex align-center">
              {{ user.vorname }} {{ user.nachname }} wurde noch kein Verkäufer
              zugewiesen!
              <v-spacer />
              <v-btn
                color="primary"
                depressed
                :to="{
                  name: 'edit-interessent',
                  params: { id: $props.id },
                }"
                >Bearbeiten</v-btn
              >
            </div>
          </v-alert>
          <router-view
            :id="$props.id"
            :key="$route.fullPath"
            @open-wv-dialog="openWvDialog($event.type, $event.wiedervorlage)"
            @open-event-dialog="openEventDialog"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-speed-dial
      v-if="
        isAuthUserAssociated ||
        $hasPermission(authUser, ['Admin', 'Geschäftsführung', 'Verwaltung'])
      "
      v-model="fab"
      fixed
      bottom
      direction="top"
      transition="slide-y-reverse-transition"
      right
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="cta" fab>
          <v-icon color="white" v-if="fab">{{ icons.mdiClose }}</v-icon>
          <v-icon color="white" v-else>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </template>
      <!-- Neue E-Mail -->
      <v-tooltip left close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            :disabled="!user.email"
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click.prevent="openNewEmailDialog"
          >
            <v-icon>{{ icons.mdiEmail }}</v-icon>
          </v-btn>
        </template>
        <span>Neue E-Mail</span>
      </v-tooltip>
      <!-- Neuer Brief -->
      <v-tooltip left close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openNewAnschreibenDialog"
          >
            <v-icon>{{ icons.mdiFileDocument }}</v-icon>
          </v-btn>
        </template>
        <span>Neuer Brief</span>
      </v-tooltip>
      <!-- Neue WV -->
      <v-tooltip left close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openWvDialog('new')"
          >
            <v-icon>{{ icons.mdiAccountReactivate }}</v-icon>
          </v-btn>
        </template>
        <span>Neue Wiedervorlage</span>
      </v-tooltip>
      <!-- Neuer Termin -->
      <v-tooltip left close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openEventDialog(null)"
          >
            <v-icon>{{ icons.mdiCalendar }}</v-icon>
          </v-btn>
        </template>
        <span>Neuer Termin</span>
      </v-tooltip>
      <!-- Neue Katalogbestellung -->
      <v-tooltip left close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="confirmKatalogbestellungDialog = true"
          >
            <v-icon>{{ icons.mdiBookOpenPageVariant }}</v-icon>
          </v-btn>
        </template>
        <span>Katalog senden</span>
      </v-tooltip>
      <!-- Referenzliste -->
      <v-tooltip left close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            :to="{ name: 'referenzen', query: { user: user.uuid } }"
          >
            <v-icon>{{ icons.mdiFormatListCheckbox }}</v-icon>
          </v-btn>
        </template>
        <span>Referenzliste</span>
      </v-tooltip>
      <!--      <v-tooltip left close-delay="300">-->
      <!--        <template v-slot:activator="{ on, attrs }">-->
      <!--          <v-btn-->
      <!--            fab-->
      <!--            small-->
      <!--            color="primary"-->
      <!--            v-bind="attrs"-->
      <!--            v-on="on"-->
      <!--            @click="confirmNewBauberatungDialog = true"-->
      <!--          >-->
      <!--            <v-icon>{{ icons.mdiAccountStar }}</v-icon>-->
      <!--          </v-btn>-->
      <!--        </template>-->
      <!--        <span>Neue Bauberatung</span>-->
      <!--      </v-tooltip>-->
    </v-speed-dial>
    <NewEmailDialog
      :value="showNewEmailDialog"
      :user="user"
      user-type="interessent"
      :submitting="sendingMail"
      @dialog:close="closeNewEmailDialog"
      @sent="onMailSent"
    />
    <NewAnschreibenDialog
      :value="showNewAnschreibenDialog"
      :user="user"
      user-type="interessent"
      @dialog:close="closeNewAnschreibenDialog"
      @submit="onAnschreibenGenerated"
    />
    <ConfirmationDialog
      :value="confirmNewWvDialog"
      @dialog:close="confirmNewWvDialog = false"
      @dialog:action="openWvDialog('new')"
    >
      <template v-slot:card-title> Wiedervorlage erstellen? </template>
      <template v-slot:default>
        <p>
          Soll eine Wiedervorlage für das Nachfassen der versendeten E-Mail
          erstellt werden?
        </p>
      </template>
      <template v-slot:action-button-text>Ja</template>
    </ConfirmationDialog>
    <ConfirmationDialog
      :value="confirmMarkAsSpamDialog"
      :loading="loadingMarkAsSpam"
      @dialog:close="confirmMarkAsSpamDialog = false"
      @dialog:action="onMarkAsSpam"
    >
      <template v-slot:card-title>
        {{ user.vorname }} {{ user.nachname }} als Spam markieren
      </template>
      <template v-slot:default>
        <p>
          Dadurch wird {{ user.vorname }} {{ user.nachname }} als Spam markiert
          und alle zugehörigen Daten werden gelöscht.<br />
          <span class="font-weight-bold error--text"
            >Dieser Vorgang kann nicht rückgängig gemacht werden!</span
          >
        </p>
        <p class="font-weight-bold">Bist Du sicher?</p>
      </template>
      <template v-slot:action-button-text>Ja</template>
    </ConfirmationDialog>
    <ConfirmationDialog
      :value="confirmKatalogbestellungDialog"
      :loading="loadingNewKatalogbestellung"
      @dialog:close="confirmKatalogbestellungDialog = false"
      @dialog:action="onNewKataloganfrage"
    >
      <template v-slot:card-title> Neue Katalogbestellung </template>
      <template v-slot:default>
        <p>
          Soll für {{ user.vorname }} {{ user.nachname }} eine neue
          Katalogbestellung erstellt werden?
        </p>
      </template>
      <template v-slot:action-button-text>Ja</template>
    </ConfirmationDialog>
    <ConfirmationDialog
      :value="confirmNewBauberatungDialog"
      :loading="loadingNewBauberatung"
      @dialog:close="confirmNewBauberatungDialog = false"
      @dialog:action="onNewBauberatung"
    >
      <template v-slot:card-title> Bauberatung erstellen </template>
      <template v-slot:default>
        <p>
          Soll für {{ user.vorname }} {{ user.nachname }} eine neue Bauberatung
          erstellt werden?
        </p>
      </template>
      <template v-slot:action-button-text>Ja</template>
    </ConfirmationDialog>
    <ConfirmationDialog
      :value="deleteDialog"
      :loading="loadingResponse"
      @dialog:close="deleteDialog = false"
      @dialog:action="deleteUser"
    >
      <template v-slot:card-title>
        {{ user.vorname }} {{ user.nachname }} löschen
      </template>
      <template v-slot:default>
        <p>
          Möchtest Du den Interessenten {{ user.vorname }}
          {{ user.nachname }} wirklich löschen?<br />
          <span class="font-weight-bold error--text"
            >Dieser Vorgang kann nicht rückgängig gemacht werden!</span
          >
        </p>
      </template>
      <template v-slot:action-button-text>Ja</template>
    </ConfirmationDialog>
    <CustomerCreationDialog
      :value="customerCreationDialog"
      :loading="customerCreating"
      @dialog:action="createCustomerFromProspect"
      @dialog:close="closeCustomerCreationDialog"
    ></CustomerCreationDialog>
    <EventDialog
      :user="user"
      user-model="User"
      :value="showEventDialog"
      :key="$route.fullPath"
      @submit="closeEventDialog($event, true)"
      @cancel="closeEventDialog($event, false)"
    />
    <WiedervorlageDialog
      :user="user"
      :wiedervorlage="selectedWv"
      :calendar-event="calendarEvent"
      :value="wvDialog"
      :show-new-form="newWv"
      :show-edit-form="editWv"
      :grund="wvGrund"
      @close="closeWvDialog"
      @open-event-dialog="openEventDialog(null)"
    />
  </v-container>
</template>

<script>
import {
  mdiChevronLeft,
  mdiStarOutline,
  mdiStar,
  mdiStarHalfFull,
  mdiContentSave,
  mdiClose,
  mdiPlus,
  mdiMail,
  mdiEmail,
  mdiBookOpenPageVariant,
  mdiAccountStar,
  mdiAccountDetails,
  mdiAccountReactivate,
  mdiDotsVertical,
  mdiFileDocumentMultiple,
  mdiFileDocument,
  mdiCalendar,
  mdiAccountOff,
  mdiFormatListCheckbox,
} from "@mdi/js";

import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import CustomerCreationDialog from "@/components/dialogs/CustomerCreationDialog.vue";
import WiedervorlageDialog from "@/components/dialogs/WiedervorlageDialog.vue";
import NewEmailDialog from "@/components/dialogs/NewEmailDialog.vue";
import NewAnschreibenDialog from "@/components/dialogs/NewAnschreibenDialog.vue";
import EventDialog from "@/components/dialogs/EventDialog.vue";
import { DateTime } from "luxon";

export default {
  name: "Show",
  components: {
    EventDialog,
    NewAnschreibenDialog,
    NewEmailDialog,
    WiedervorlageDialog,
    CustomerCreationDialog,
    ConfirmationDialog,
  },
  props: ["type", "id"],
  data() {
    return {
      icons: {
        mdiChevronLeft,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiContentSave,
        mdiClose,
        mdiPlus,
        mdiMail,
        mdiEmail,
        mdiBookOpenPageVariant,
        mdiAccountStar,
        mdiDotsVertical,
        mdiAccountReactivate,
        mdiFileDocument,
        mdiCalendar,
        mdiAccountOff,
        mdiFormatListCheckbox,
      },
      navItems: [
        {
          title: "Details",
          path: `/backend/users/interessenten/${this.$props.id}`,
          icon: mdiAccountDetails,
        },
        {
          title: "Dokumente",
          path: `/backend/users/interessenten/${this.$props.id}/documents`,
          icon: mdiFileDocumentMultiple,
        },
      ],
      calendarEvent: {},
      fab: false,
      showNewEmailDialog: false,
      showNewAnschreibenDialog: false,
      deleteDialog: false,
      customerCreationDialog: false,
      customerCreating: false,
      wvDialog: false,
      showEventDialog: false,
      wvGrund: null,
      newWv: false,
      editWv: false,
      selectedWv: null,
      ratingEdited: false,
      savingRating: false,
      confirmMarkAsSpamDialog: false,
      confirmKatalogbestellungDialog: false,
      confirmNewBauberatungDialog: false,
      confirmNewWvDialog: false,
      loadingUser: false,
      loadingNewBauberatung: false,
      loadingNewKatalogbestellung: false,
      loadingMarkAsSpam: false,
      loadingResponse: false,
      sendingMail: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    authUser() {
      return this.$store.state.user.authUser;
    },
    catalogueRequestsCount() {
      return this.user.katalogbestellungen
        ? this.user.katalogbestellungen.length
        : 0;
    },
    bauberatungenCount() {
      return this.user.bauberatungen ? this.user.bauberatungen.length : 0;
    },
    isAuthUserAssociated() {
      return (
        (this.user.verkaeufer &&
          this.authUser.id === this.user.verkaeufer.id) ||
        this.$hasPermission(this.authUser, [
          "Admin",
          "Geschäftsführung",
          "Verwaltung",
          "Rechnungswesen",
        ])
      );
    },
  },
  async created() {
    if (this.user.id === undefined) {
      await this.fetchSingleUser();
    }
  },
  async updated() {
    if (this.user.uuid && this.$props.id !== this.user.uuid) {
      await this.fetchSingleUser();
    }
  },
  methods: {
    async fetchSingleUser() {
      this.loadingUser = true;
      await ApiService.getSingleUser(this.$props.id)
        .then((res) => {
          this.$store.commit("user/SET_USER", res.data.data);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingUser = false;
        });
    },
    submitRating() {
      const data = {
        uuid: this.user.uuid,
        rating: this.user.rating,
      };

      this.savingRating = true;
      ApiService.rateUser(data)
        .then((res) => {
          this.$store.commit("user/SET_USER", res.data.data);
          this.ratingEdited = false;
        })
        .finally(() => {
          this.savingRating = false;
        });
    },
    cancelRatingInput() {
      this.ratingEdited = false;
      this.rating = this.user.rating;
    },
    async onMarkAsSpam() {
      this.loadingMarkAsSpam = true;

      await ApiService.markAsSpam(this.$props.id)
        .then(() => {
          const notification = {
            type: "success",
            title: "Erfolg",
            message: "Interessent als Spam markiert.",
          };

          this.$store.commit("notifications/ADD", notification);
          this.$router.push({ name: "list-interessenten" });
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingMarkAsSpam = false;
        });
    },
    async onNewBauberatung() {
      const payload = {
        uuid: this.user.uuid,
        anrede: this.user.anrede,
        vorname: this.user.vorname,
        nachname: this.user.nachname,
        strasse: this.user.strasse,
        hausnr: this.user.hausnr,
        plz: this.user.plz,
        ort: this.user.ort,
        herkunft: "Talis",
      };

      this.loadingNewBauberatung = true;
      await ApiService.createBauberatung(payload)
        .then(() => {
          const notification = {
            type: "success",
            title: "Erfolg",
            message: "Neue Bauberatung erstellt.",
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingNewBauberatung = false;
        });

      this.confirmNewBauberatungDialog = false;
    },
    async onNewKataloganfrage() {
      const payload = {
        uuid: this.user.uuid,
        vorname: this.user.vorname,
        nachname: this.user.nachname,
        strasse: this.user.strasse,
        hausnr: this.user.hausnr,
        plz: this.user.plz,
        ort: this.user.ort,
        message: null,
        zustellung: "print",
      };

      this.loadingNewKatalogbestellung = true;
      await ApiService.newKatalogbestellung(payload)
        .then(() => {
          const notification = {
            type: "success",
            title: "Erfolg",
            message: "Neue Kataloganfrage erstellt.",
          };

          this.$store.commit("notifications/ADD", notification);
          this.fetchSingleUser();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingNewKatalogbestellung = false;
        });

      this.confirmKatalogbestellungDialog = false;
    },
    showNavChildItem(item) {
      let show = false;

      if (
        item.title === "Katalogbestellungen" &&
        this.catalogueRequestsCount !== 0
      ) {
        show = true;
      } else if (
        item.title === "Bauberatungen" &&
        this.bauberatungenCount !== 0
      ) {
        show = true;
      }

      return show;
    },
    openCustomerCreationDialog() {
      this.customerCreationDialog = true;
    },
    closeCustomerCreationDialog() {
      this.customerCreationDialog = false;
    },
    openEventDialog(event = null) {
      this.calendarEvent = {};

      if (event) {
        this.initializeEventFields(event);
      }

      this.showEventDialog = true;
    },
    initializeEventFields(event) {
      this.calendarEvent = { ...event };
      this.calendarEvent.startDate = DateTime.fromSQL(event.start).toISODate();
      this.calendarEvent.endDate = DateTime.fromSQL(event.end).toISODate();
      this.calendarEvent.startTime = DateTime.fromSQL(event.start).toFormat(
        "HH:mm"
      );
      this.calendarEvent.endTime = DateTime.fromSQL(event.end).toFormat(
        "HH:mm"
      );
      this.calendarEvent.guest = this.user;
    },
    closeEventDialog(event, reload = false) {
      if (reload === true) {
        this.fetchSingleUser();
      }

      if (event) {
        this.calendarEvent = {};
        this.initializeEventFields(event);
      }

      this.showEventDialog = false;
    },
    openWvDialog(type, wiedervorlage = null) {
      if (wiedervorlage) {
        this.selectedWv = wiedervorlage;
      }

      if (this.confirmNewWvDialog === true) {
        this.confirmNewWvDialog = false;
      }

      if (type === "new") {
        this.newWv = true;
        this.editWv = false;
        this.wvGrund = "E-Mail nachfassen";
      } else {
        this.newWv = false;
        this.editWv = true;
      }

      this.wvDialog = true;
    },
    closeWvDialog() {
      this.wvDialog = false;
      this.newWv = false;
      this.editWv = false;
      this.selectedWv = null;
    },
    openNewAnschreibenDialog() {
      this.showNewAnschreibenDialog = true;
    },
    closeNewAnschreibenDialog() {
      this.showNewAnschreibenDialog = false;
    },
    onAnschreibenGenerated() {
      this.closeNewAnschreibenDialog();
      this.fetchSingleUser();
    },
    openNewEmailDialog() {
      // this.fetchSingleUser();
      this.showNewEmailDialog = true;
    },
    closeNewEmailDialog() {
      // this.fetchSingleUser();
      this.showNewEmailDialog = false;
    },
    onMailSent() {
      this.closeNewEmailDialog();
      this.fetchSingleUser();
      this.openWvDialog("new");
    },
    async createCustomerFromProspect() {
      const payload = {
        uuid: this.user.uuid,
      };

      this.customerCreating = true;
      await ApiService.createCustomerFromProspect(payload)
        .then((res) => {
          this.$router
            .push({
              name: "show-customer-details",
              params: { id: res.data.data.uuid },
            })
            .then(() => {
              const notification = {
                type: "success",
                title: "Kunde erstellt.",
                message:
                  "Der Kundendatensatz wurde erfolgreich erstellt und das Bauvorhaben angelegt.",
              };

              this.$store.commit("notifications/ADD", notification);
            });
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.customerCreating = false;
        });
    },
    async activateUser() {
      await ApiService.activateUser(this.user.uuid).then(() => {
        const notification = {
          type: "success",
          title: "Erfolg",
          message: `Benutzer "${this.user.vorname} ${this.user.nachname}" wurde aktiviert`,
        };
        this.$store.commit("notifications/ADD", notification);
        this.fetchSingleUser();
      });
    },
    async deactivateUser() {
      await ApiService.deactivateUser(this.user.uuid).then(() => {
        const notification = {
          type: "success",
          title: "Erfolg",
          message: `Benutzer "${this.user.vorname} ${this.user.nachname}" wurde deaktiviert`,
        };
        this.$store.commit("notifications/ADD", notification);
        this.fetchSingleUser();
      });
    },
    async deleteUser() {
      this.loadingResponse = true;
      await ApiService.deleteUser(this.user.uuid)
        .then((res) => {
          const notification = {
            type: "success",
            title: "Erledigt",
            message: res.data.message,
          };
          this.$store.commit("notifications/ADD", notification);
          this.$router.push("/backend/users/interessenten");
        })
        .catch(() => {})
        .finally(() => {
          this.loadingResponse = false;
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.matched.some((record) => record.path.includes(this.user.uuid))) {
      next();
    } else {
      this.$store.commit("user/SET_USER", {});
      next();
    }
  },
};
</script>

<style scoped>
.show-user {
  position: relative;
}
</style>
