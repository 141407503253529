<template>
  <v-card class="pa-5">
    <v-row>
      <v-col cols="12" lg="6" class="pb-0">
        <p class="font-weight-bold">Ursache</p>
        <v-combobox
          ref="formFieldWVUrsache"
          v-model="formFields.grund"
          :items="gruende"
          outlined
          label="Titel"
          clearable
          @input="refreshWiedervorlageDatum(formFields.grund)"
        ></v-combobox>
      </v-col>
      <v-col cols="12" lg="6" class="pb-0">
        <p class="font-weight-bold">Datum</p>
        <v-menu
          v-model="formFields.menuStatus"
          :close-on-content-click="false"
          min-width="auto"
          :disabled="!formFields.grund"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="datumFormatiert"
              :disabled="!formFields.grund"
              outlined
              label="Datum"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formFields.datum"
            color="cta"
            locale="de-de"
            :first-day-of-week="1"
            :min="minDate"
            no-title
            scrollable
            show-adjacent-months
            @input="formFields.menuStatus = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-textarea
          v-model="formFields.anmerkungen"
          outlined
          label="Anmerkungen"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-card-actions class="px-0">
      <v-spacer />
      <v-btn :disabled="saving" large depressed @click="closeDialog"
        >Abbrechen</v-btn
      >
      <v-btn
        color="cta"
        :disabled="saving"
        large
        depressed
        @click="saveWiedervorlage"
        class="white--text"
        >Speichern
        <v-progress-circular
          v-if="saving"
          indeterminate
          class="ml-2"
        ></v-progress-circular
      ></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "FormEditWiedervorlage",
  props: {
    user: {
      type: Object,
      required: true,
    },
    wiedervorlage: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    gruende: [
      "E-Mail nachfassen",
      "Telefon nachfassen",
      "Angebot",
      "Angebot nachfassen",
      "Termin",
      "Termin nachfassen",
      "Planungsvertrag",
      "Planungsvertrag nachfassen",
      "WV 1 Monat",
      "WV 2 Monate",
      "WV 3 Monate",
      "WV 6 Monate",
      "WV 12 Monate",
      "Inaktiv",
    ],
    formFields: {
      grund: null,
      datum: DateTime.now().toISODate(),
      anmerkungen: "",
      menuStatus: false,
    },
    saving: false,
  }),
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    minDate() {
      return DateTime.now().toISODate();
    },
    datumFormatiert() {
      if (this.formFields.datum) {
        return DateTime.fromISO(this.formFields.datum).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        );
      } else {
        return DateTime.now().toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
      }
    },
  },
  mounted() {
    console.log("WV:", this.wiedervorlage);
    this.setFormFields();
  },
  methods: {
    setFormFields() {
      this.formFields.datum = DateTime.fromISO(
        this.wiedervorlage.datum
      ).toISODate();
      this.formFields.grund = this.wiedervorlage.grund;
      this.formFields.anmerkungen = this.wiedervorlage.anmerkungen;
    },
    async fetchSingleUser() {
      await ApiService.getSingleUser(this.$route.params.id)
        .then((res) => {
          this.$store.commit("user/SET_USER", res.data.data);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        });
    },
    refreshWiedervorlageDatum(grund) {
      switch (grund) {
        case "E-Mail nachfassen":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 4);
          break;
        case "Telefon nachfassen":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 4);
          break;
        case "Angebot":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 7);
          break;
        case "Angebot nachfassen":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 3);
          break;
        case "Termin":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 3);
          break;
        case "Termin nachfassen":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 3);
          break;
        case "Planungsvertrag":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 7);
          break;
        case "Planungsvertrag nachfassen":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 14);
          break;
        case "Kaufvertrag":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 7);
          break;
        case "WV 1 Monat":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 30);
          break;
        case "WV 2 Monate":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 61);
          break;
        case "WV 3 Monate":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 92);
          break;
        case "WV 6 Monate":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 182);
          break;
        case "WV 12 Monate":
          this.formFields.datum = this.addDays(DateTime.now().toISODate(), 365);
          break;
        default:
          this.formFields.datum = DateTime.now().toISODate();
      }
    },
    addDays(date, days) {
      if (DateTime.fromISO(date).toISODate() === DateTime.now().toISODate()) {
        return DateTime.fromISO(date).plus({ days: days }).toISODate();
      }
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      );
    },
    closeDialog() {
      this.$emit("close");
    },
    saveWiedervorlage() {
      const payload = {
        grund: this.formFields.grund,
        datum: this.formFields.datum,
        anmerkungen: this.formFields.anmerkungen,
        kunde: this.user.uuid,
        wiedervorlage: this.wiedervorlage,
      };

      this.saving = true;
      ApiService.updateWiedervorlage(payload)
        .then(() => {
          const notification = {
            type: "success",
            title: "Erledigt",
            message: "Wiedervorlage gespeichert.",
          };

          this.$store.commit("notifications/ADD", notification);
          this.closeDialog();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.saving = false;
        });

      this.fetchSingleUser();
    },
  },
};
</script>

<style scoped></style>
