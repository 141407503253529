<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <v-sheet outlined rounded class="pa-5">
          <v-list rounded nav>
            <template v-for="(navItem, index) in navItems">
              <v-list-item
                v-if="navItem.gate === undefined || $can(navItem.gate)"
                :key="`navItem-${index}`"
              >
                <v-list-item-icon>
                  <v-icon>{{ navItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link
                      :to="`/backend/users/customers/${$props.id}${navItem.path}`"
                      class="text-decoration-none"
                      active-class="font-weight-bold"
                    >
                      {{ navItem.text }}
                    </router-link>
                  </v-list-item-title>
                  <template v-for="(child, index) in navItem.children">
                    <v-list-item-subtitle
                      v-if="child.gate === undefined || $can(child.gate)"
                      :key="`child-${index}`"
                      class="mb-2"
                      :class="index === 0 ? 'mt-3' : ''"
                    >
                      <router-link
                        :to="`/backend/users/customers/${$props.id}${navItem.path}/${child.path}`"
                        class="text-decoration-none"
                        active-class="font-weight-bold"
                      >
                        {{ child.title }}
                      </router-link>
                    </v-list-item-subtitle>
                  </template>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="10">
        <v-card class="pa-5">
          <v-card-title class="pt-0 px-0">
            <v-btn icon class="mr-2" :to="{ name: 'list-customers' }" exact>
              <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
            <span class="text-h5 mr-3"
              >{{ customerAnrede }} {{ customer.vorname }}
              {{ customer.nachname }} - {{ bNummern }}</span
            >
            <v-btn
              v-if="customer.can?.edit"
              color="cta"
              icon
              :to="{ name: 'edit-customer' }"
              ><v-icon>{{ icons.mdiPencil }}</v-icon></v-btn
            >
          </v-card-title>
          <router-view :id="$props.id" :key="$route.fullPath" />
        </v-card>
      </v-col>
    </v-row>
    <v-speed-dial
      v-if="customer.can?.showActionsButton"
      v-model="fab"
      fixed
      bottom
      direction="top"
      transition="slide-y-reverse-transition"
      right
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="cta" fab>
          <v-icon color="white" v-if="fab">{{ icons.mdiClose }}</v-icon>
          <v-icon color="white" v-else>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </template>
      <v-tooltip v-if="customer.can?.sendEmail" left close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            :disabled="!customer.email"
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openNewEmailDialog"
          >
            <v-icon>{{ icons.mdiEmail }}</v-icon>
          </v-btn>
        </template>
        <span>Neue E-Mail</span>
      </v-tooltip>
      <v-tooltip v-if="customer.can?.sendLetter" left close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openNewAnschreibenDialog"
          >
            <v-icon>{{ icons.mdiFileDocument }}</v-icon>
          </v-btn>
        </template>
        <span>Neuer Brief</span>
      </v-tooltip>
      <!-- Neuer Termin -->
      <v-tooltip left open-delay="500" close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openEventDialog"
          >
            <v-icon>{{ icons.mdiCalendar }}</v-icon>
          </v-btn>
        </template>
        <span>Neuer Termin</span>
      </v-tooltip>
      <v-tooltip v-if="customer.can?.createInvoice" left close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            :to="{
              name: 'new-customer-invoice',
              params: { id: $route.params.id },
            }"
          >
            <v-icon>{{ icons.mdiAccountCash }}</v-icon>
          </v-btn>
        </template>
        <span>Neue Rechnung</span>
      </v-tooltip>
      <v-tooltip v-if="customer.can?.createBauvorhaben" left close-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openNewBauvorhabenConfirmationDialog"
          >
            <v-icon>{{ icons.mdiOfficeBuildingPlus }}</v-icon>
          </v-btn>
        </template>
        <span>Bauvorhaben anlegen</span>
      </v-tooltip>
    </v-speed-dial>
    <!--    <EmailContainer-->
    <!--      :dialog.sync="showNewEmailDialog"-->
    <!--      @mail:sent="closeNewEmailDialog"-->
    <!--      user-type="kunde"-->
    <!--    />-->
    <NewEmailDialog
      :value="showNewEmailDialog"
      :user="customer"
      user-type="kunde"
      :submitting="sending"
      @dialog:close="closeNewEmailDialog"
      @sent="onMailSent"
    />
    <NewAnschreibenDialog
      :value="showNewAnschreibenDialog"
      :user="customer"
      user-type="kunde"
      @dialog:close="closeNewAnschreibenDialog"
      @submit="onAnschreibenGenerated"
    />
    <EventDialog
      :user="customer"
      user-model="Customer"
      :value="showEventDialog"
      :key="$route.fullPath"
      @submit="closeEventDialog(true)"
      @cancel="closeEventDialog(false)"
    />
    <ConfirmationDialog
      :value="showConfirmationDialog"
      :loading="saving"
      @dialog:action="createBauvorhaben"
      @dialog:close="closeNewBauvorhabenConfirmationDialog"
    >
      <template v-slot:card-title> Bestätigung Bauvorhaben anlegen </template>
      <p>
        Möchtest du für den Kunden
        <span class="font-weight-bold"
          >{{ customer.vorname }} {{ customer.nachname }}</span
        >
        ein neues Bauvorhaben anlegen?
      </p>
      <p>Bitte bestätige diese Aktion.</p>
      <template v-slot:action-button-text> Anlegen </template>
    </ConfirmationDialog>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiFileDocumentOutline,
  mdiInformation,
  mdiFileDocumentMultiple,
  mdiFileDocumentAlertOutline,
  mdiClose,
  mdiPlus,
  mdiMail,
  mdiEmail,
  mdiFileDocument,
  mdiPencil,
  mdiOfficeBuilding,
  mdiAccountCash,
  mdiCash,
  mdiOfficeBuildingPlus,
  mdiCalendar,
} from "@mdi/js";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import NewAnschreibenDialog from "@/components/dialogs/NewAnschreibenDialog.vue";
import NewEmailDialog from "@/components/dialogs/NewEmailDialog.vue";
import EventDialog from "@/components/dialogs/EventDialog.vue";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { DateTime } from "luxon";

export default {
  name: "ShowCustomerLayout",
  components: {
    ConfirmationDialog,
    NewEmailDialog,
    NewAnschreibenDialog,
    EventDialog,
  },
  props: ["id"],
  data() {
    return {
      icons: {
        mdiChevronLeft,
        mdiClose,
        mdiPlus,
        mdiMail,
        mdiEmail,
        mdiFileDocument,
        mdiPencil,
        mdiAccountCash,
        mdiCash,
        mdiOfficeBuildingPlus,
        mdiCalendar,
      },
      navItems: [
        { text: "Details", path: "/details", icon: mdiInformation },
        { text: "Bauvorhaben", path: "/bauvorhaben", icon: mdiOfficeBuilding },
        {
          text: "Rechnungen",
          path: "/invoices",
          icon: mdiFileDocumentOutline,
          gate: "invoices_access",
          children: [
            {
              title: "Eingang",
              path: "incoming",
              gate: "incoming_invoices_index",
            },
          ],
        },
        {
          text: "Zahlungen",
          path: "/invoices/payments",
          gate: "payments_access",
          icon: mdiCash,
        },
        {
          text: "Mahnungen",
          path: "/invoices/reminders",
          gate: "invoice_reminders_create",
          icon: mdiFileDocumentAlertOutline,
        },
        {
          text: "Dokumente",
          path: "/documents",
          icon: mdiFileDocumentMultiple,
        },
      ],
      calendarEvent: {},
      fab: false,
      showConfirmationDialog: false,
      showNewEmailDialog: false,
      showNewAnschreibenDialog: false,
      showEventDialog: false,
      generatingAnschreiben: false,
      sending: false,
      saving: false,
    };
  },
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    customer() {
      return this.$store.state.user.user;
    },
    bNummern() {
      if (this.customer.bauvorhaben && this.customer.bauvorhaben.length > 0) {
        return this.customer.bauvorhaben.map((item) => item.bv_nr).join(", ");
      } else if (this.customer.interne_nummer) {
        return this.customer.interne_nummer;
      } else {
        return "";
      }
    },
    customerAnrede() {
      let anrede = "";
      if (this.customer) {
        if (this.customer.anrede === "Firma") {
          if (this.customer.firma) {
            anrede = this.customer.anrede + " " + this.customer.firma;
          } else {
            anrede = this.customer.anrede;
          }
        } else if (
          this.customer.anrede !== "Keine" &&
          this.customer.anrede !== "Divers"
        ) {
          anrede = this.customer.anrede;
        } else {
          return anrede;
        }
      }

      return anrede;
    },
  },
  async mounted() {
    await this.fetchSingleUser();
  },
  async updated() {
    if (this.customer.uuid && this.$props.id !== this.customer.uuid) {
      await this.fetchSingleUser();
    }
  },
  methods: {
    initializeEventFields(event) {
      this.calendarEvent = { ...event };
      this.calendarEvent.startDate = DateTime.now().toISODate();
      this.calendarEvent.endDate = DateTime.now().toISODate();
      this.calendarEvent.startTime = DateTime.fromSQL(event.start).toFormat(
        "HH:mm"
      );
      this.calendarEvent.endTime = DateTime.fromSQL(event.end).toFormat(
        "HH:mm"
      );
      this.calendarEvent.guest = this.user;
    },
    async fetchSingleUser() {
      this.$store.commit("loading/LOADING_SINGLE_USER", true);
      await ApiService.fetchCustomer(this.$route.params.id)
        .then(async (res) => {
          this.$store.commit("user/SET_USER", res.data.data);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };
          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.$store.commit("loading/LOADING_SINGLE_USER", false);
        });
    },
    async createBauvorhaben() {
      const payload = {
        customer_id: this.customer.id,
        verkaeufer_id: this.customer.verkaeufer_id,
      };

      this.saving = true;
      await ApiService.createBauvorhaben(payload)
        .then(async (res) => {
          const notification = {
            type: "success",
            title: "Bauvorhaben angelegt.",
            message:
              "Das Bauvorhaben wurde erfolgreich angelegt. Die B-Nr. lautet: " +
              res.data.bv_nr,
          };

          this.closeNewBauvorhabenConfirmationDialog();

          this.$store.commit("notifications/ADD", notification);

          await this.fetchSingleUser();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler.",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    openNewBauvorhabenConfirmationDialog() {
      this.showConfirmationDialog = true;
    },
    closeNewBauvorhabenConfirmationDialog() {
      this.showConfirmationDialog = false;
    },
    openNewEmailDialog() {
      this.showNewEmailDialog = true;
    },
    closeNewEmailDialog() {
      // this.fetchSingleUser();
      this.showNewEmailDialog = false;
    },
    openNewAnschreibenDialog() {
      this.showNewAnschreibenDialog = true;
    },
    closeNewAnschreibenDialog() {
      this.showNewAnschreibenDialog = false;
    },
    openEventDialog() {
      this.showEventDialog = true;
    },
    closeEventDialog(reload = false) {
      if (reload === true) {
        this.fetchSingleUser();
      }
      this.showEventDialog = false;
    },
    onAnschreibenGenerated() {
      this.closeNewAnschreibenDialog();
      this.fetchSingleUser();
    },
    onMailSent() {
      this.closeNewEmailDialog();
      this.fetchSingleUser();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.matched.some((record) => record.path.includes(this.customer.uuid))) {
      next();
    } else {
      this.$store.commit("user/SET_USER", {});
      next();
    }
  },
};
</script>

<style scoped></style>
