<template>
  <div>
    <v-card class="pa-5">
      <v-row>
        <v-col cols="12" lg="6" class="pb-0">
          <p class="font-weight-bold">Ursache</p>
          <v-combobox
            ref="formFieldWVUrsache"
            v-model="wiedervorlage.grund"
            :items="gruende"
            outlined
            label="Titel"
            clearable
            @input="refreshWiedervorlageDatum(wiedervorlage.grund)"
          ></v-combobox>
        </v-col>
        <v-col cols="12" lg="6" class="pb-0">
          <p class="font-weight-bold">Datum</p>
          <v-menu
            v-model="wiedervorlage.menuStatus"
            :close-on-content-click="false"
            min-width="auto"
            :disabled="
              !wiedervorlage.grund || wiedervorlage.grund === 'Inaktiv'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="datumFormatiert"
                :disabled="
                  !wiedervorlage.grund || wiedervorlage.grund === 'Inaktiv'
                "
                outlined
                label="Datum"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="wiedervorlage.datum"
              color="cta"
              locale="de-de"
              :first-day-of-week="1"
              :min="minDate"
              no-title
              scrollable
              show-adjacent-months
              @input="wiedervorlage.menuStatus = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-textarea
            v-model="wiedervorlage.anmerkungen"
            outlined
            label="Anmerkungen"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-card-actions class="px-0">
        <v-spacer />
        <v-btn :disabled="awaitingResponse" large depressed @click="closeDialog"
          >Abbrechen</v-btn
        >
        <v-btn
          color="cta"
          :disabled="awaitingResponse"
          large
          depressed
          @click="saveWiedervorlage"
          class="white--text"
          >Speichern
          <v-progress-circular
            v-if="awaitingResponse"
            indeterminate
            class="ml-2"
          ></v-progress-circular
        ></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "FormNewWiedervorlage",
  props: {
    user: {
      type: Object,
      required: true,
    },
    grund: {
      type: String,
      required: false,
    },
    calendarEvent: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    gruende: [
      "E-Mail nachfassen",
      "Telefon nachfassen",
      "Inaktiv",
      "Angebot erstellen",
      "Angebot nachfassen",
      "Termin",
      "Planungsvertrag nachfassen",
      "Zeichnungsfreigabe",
      "Baugenehmigung",
      "Bemusterungsende",
      "Kaufvertrag",
      "Nachtrag",
      "WV 1 Monat",
      "WV 2 Monate",
      "WV 3 Monate",
      "WV 6 Monate",
      "WV 12 Monate",
    ],
    wiedervorlage: {
      grund: "E-Mail nachfassen",
      datum: DateTime.now().toISODate(),
      anmerkungen: null,
      menuStatus: false,
    },
    saving: false,
  }),
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    minDate() {
      return DateTime.now().toISODate();
    },
    datumFormatiert() {
      if (this.wiedervorlage.datum) {
        return DateTime.fromISO(this.wiedervorlage.datum).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        );
      } else {
        return DateTime.now().toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
      }
    },
    awaitingResponse() {
      return this.$store.state.loading.awaitResponse;
    },
  },
  watch: {
    "wiedervorlage.grund": {
      handler(val) {
        if (val === "Termin") {
          this.$emit("open-event-dialog");
        }
      },
    },
    calendarEvent() {
      if (this.calendarEvent) {
        this.wiedervorlage.grund = "Termin";
        this.wiedervorlage.datum = this.calendarEvent.startDate;
      }
    },
  },
  mounted() {
    if (this.$props.grund) {
      this.wiedervorlage.grund = this.$props.grund;
      this.refreshWiedervorlageDatum(this.$props.grund);
    }
  },
  methods: {
    async fetchSingleUser() {
      await ApiService.getSingleUser(this.$route.params.id)
        .then((res) => {
          // this.user = res.data.data;
          this.$store.commit("user/SET_USER", res.data.data);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        });
    },
    refreshWiedervorlageDatum(grund) {
      switch (grund) {
        case "E-Mail nachfassen":
          // this.wiedervorlage.datum = DateTime.now().toISODate();
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            4
          );
          break;
        case "Telefon nachfassen":
          // this.wiedervorlage.datum = DateTime.now().toISODate();
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            4
          );
          break;
        case "Angebot":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            7
          );
          break;
        case "Angebot nachfassen":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            3
          );
          break;
        case "Termin":
          this.wiedervorlage.datum = this.calendarEvent
            ? this.calendarEvent.startDate
            : this.addDays(DateTime.now().toISODate(), 3);
          break;
        case "Termin nachfassen":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            3
          );
          break;
        case "Planungsvertrag":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            7
          );
          break;
        case "Planungsvertrag nachfassen":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            14
          );
          break;
        case "Kaufvertrag":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            7
          );
          break;
        case "WV 1 Monat":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            30
          );
          break;
        case "WV 2 Monate":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            61
          );
          break;
        case "WV 3 Monate":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            92
          );
          break;
        case "WV 6 Monate":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            182
          );
          break;
        case "WV 12 Monate":
          this.wiedervorlage.datum = this.addDays(
            DateTime.now().toISODate(),
            365
          );
          break;
        default:
          this.wiedervorlage.datum = DateTime.now().toISODate();
      }
    },
    addDays(date, days) {
      if (DateTime.fromISO(date).toISODate() === DateTime.now().toISODate()) {
        return DateTime.fromISO(date).plus({ days: days }).toISODate();
      }
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      );
    },
    closeDialog() {
      this.$emit("close");
    },
    async saveWiedervorlage() {
      const payload = {
        grund: this.wiedervorlage.grund,
        datum: this.wiedervorlage.datum,
        anmerkungen: this.wiedervorlage.anmerkungen,
        kunde: this.user.uuid,
      };

      this.saving = true;
      await ApiService.storeWiedervorlage(payload)
        .then(() => {
          const notification = {
            type: "success",
            title: "Erledigt",
            message: "Neue Wiedervorlage gespeichert.",
          };
          this.$store.commit("notifications/ADD", notification);
          this.closeDialog();
          this.fetchSingleUser();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
            timeout: false,
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped></style>
